import React, { useEffect, useState } from 'react';
import { toast, Zoom } from "react-toastify";
import { RiFileEditFill, RiDeleteBin6Fill } from 'react-icons/ri';
import axios from 'axios';

const initialValue = {
  blog_name: "",
  blog_description: "",
  blog_img: [],



}
export default function CarDetailsForm() {
  const [data, setData] = useState(initialValue);
  const [taxiPrint, setTaxiPrint] = useState([]);
  const [cityPrint, setCityPrint] = useState([]);
  const [selectCity, setSelectCity] = useState();
  const [edit, setEdit] = useState(false);
  const [selectPrint, setSelectPrint] = useState();
  const [formData, setFormData] = useState(initialValue);
  const [allDataTable, setAllDataTable] = useState([]);
  const [showImg, setShowImg] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  // delete products.....
  const handleDelete = id => {
    console.log(id);
    try {
      fetch(`https://hapi.hindustanrides.in/api/v1/deleteBlogs/${id}`, {
        method: "DELETE"
      })
        .then(response => response.json())
        .then(() => {
          setAllDataTable(values => {
            return values.filter(item => item.id !== id)
          })
        })
      fatchData();
      toast.success("Delete Taxi Successfully")
    } catch (err) {
      console.log(err)
    }
  }

  // update get data form.....
  const getUpdateData = (id) => {
    
    const user = allDataTable.find((user) => user._id === id);
    const imageData = `https://hapi.hindustanrides.in/uploads/blog/${user.blog_img}`
    setShowImg(imageData)
    setData(user);
    setFormData({
      ...initialValue,
      blog_name: user.blog_name,
      blog_description: user.blog_description,
      blog_img: imageData, // Store the array of image data
      id:user._id
    });


    setEdit(true);
  };


  // // update data.....
  // const handleUpdate = async (id) => {
  //   try {
  //     const formDataToSend = new FormData();
  //     formDataToSend.append('blog_name', formData.blog_name);
  //     formDataToSend.append('blog_description', formData.blog_description);

  //     // Append existing image if no new image selected
  //     if (formData.blog_img.length === 0) {
  //       formDataToSend.append('blog_img', formData.blog_img[0].name);
  //     } else {
  //       // Append new image if selected
  //       formDataToSend.append('blog_img', formData.blog_img[0]);
  //     }

  //     const addRecordEndpoint = `https://hapi.hindustanrides.in/api/v1/editBlogs/${formData.id}`;
  //     const response = await fetch(addRecordEndpoint, {
  //       method: 'PATCH', 
  //       body: formDataToSend,
  //     });
  //     const jsonResponse = await response.json();
  //     // if (jsonResponse.status === 'success') {
  //     //   setData(jsonResponse);
  //     //   fatchData();
  //     //   setEdit(false);
  //     //   setFormData(initialValue);
  //     //   toast.success('Update Taxi Data Successfully');
  //     //   window.location.reload();
  //     //   setFormData(initialValue);
  //     //   setCityPrint(null);
  //     //   setTaxiPrint(null);
  //     // } else {
  //     //   toast.error('Error updating taxi data. Please try again.');
  //     // }
  //   } catch (err) {

  //   }
  // };
  // update data.....


// update data.....
const handleUpdate = async (id) => {
  try {
    const formDataToSend = new FormData();
    formDataToSend.append('blog_name', formData.blog_name);
    formDataToSend.append('blog_description', formData.blog_description);

    // Append existing image if no new image selected
    if (formData.blog_img.length === 0) {
      formDataToSend.append('blog_img', formData.blog_img[0].name);
    } else {
      // Append new image if selected
      formDataToSend.append('blog_img', formData.blog_img[0]);
    }

    const addRecordEndpoint = `https://hapi.hindustanrides.in/api/v1/editBlogs/${formData.id}`;
    const response = await fetch(addRecordEndpoint, {
      method: 'PATCH', 
      body: formDataToSend,
    });

    const jsonResponse = await response.json();

    if (jsonResponse.status === 'success') {
      // Update state and perform other actions on success
      setData(jsonResponse);
      fatchData();
      setEdit(false);
      setFormData(initialValue);
      toast.success('Update Taxi Data Successfully');
      // Remove the window.location.reload() as it may interfere with React's state management
      setFormData(initialValue);
      setCityPrint(null);
      setTaxiPrint(null);
    } else {
      toast.error('Error updating taxi data. Please try again.');
    }
  } catch (err) {
    console.error(err);
    // Handle errors here if needed
  }
};

  

  // form submit.....
  let handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formDataToSend = new FormData();
      formDataToSend.append('blog_name', formData.blog_name);
      formDataToSend.append('blog_description', formData.blog_description);

      for (let i = 0; i < formData.blog_img.length; i++) {
        formDataToSend.append('blog_img', formData.blog_img[i]);
      }

      const response = await axios.post(`https://hapi.hindustanrides.in/api/v1/addBlogs`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.status === "success") {
        toast.success(response.data.message);
        fatchData();
        setFormData(initialValue);
        setCityPrint(null);
        setTaxiPrint(null);
        window.location.reload();
      }
      if (response.data.status === "fail") {
        toast.error(response.data.message);
      }

    } catch (err) {
      console.log(err);
    }
  };



  /// get all data-----
  const fatchData = async () => {

    try {
      const addRecordEndpoint = "https://hapi.hindustanrides.in/api/v1/getBlogs";

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      }

      const response = await fetch(addRecordEndpoint, options);
      const jsonResponse = await response.json();
      setAllDataTable(jsonResponse?.data);

    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fatchData();
  }, []);

  const handlechange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleImageChange = (e) => {
    const files = e.target.files;
    setFormData({
      ...formData,
      blog_img: [...files],
    });

    // To display the first image preview
    if (files.length > 0) {
      setShowImg(URL.createObjectURL(files[0]));
    }
  };

  return <>
    <main className='main-container'>
      <h1 className='text-black text-[20px] py-3 px-8'>Blog Details Form</h1>
      <form>
        <div className=" max-w-sm mx-auto py-4 bg-gray-200 my-4 px-4 shadow-xl rounded-xl ">

          <div className=" py-2 pt-2 ">
            <input className="w-full border border-gray-400 p-2 focus:outline-none rounded-md text-black"
              type="text" placeholder="Blog name" name='blog_name' onChange={handlechange} value={formData.blog_name} />
          </div>
          <div className=" py-2">
            <input className="w-full border border-gray-400 p-2 focus:outline-none rounded-md text-black"
              type="text" placeholder="Blog dscription" name='blog_description' onChange={handlechange} value={formData.blog_description} />
          </div>
          <div className=" py-2">
            <input
              type="file"
              name="blog_img"
              className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none text-[#000] focus:border-blue-500"
              placeholder="Enter product image"
              onChange={handleImageChange}
              multiple
              accept="image/*"
            />
            {/* {edit ? <img src={showImg} className='h-24 w-40'/> : ""} */}
            {edit && <img src={showImg} alt="Preview" className='h-full w-40' />}
          </div>
          <div className='flex'>
            {edit ? <button type='button' className="mx-auto block bg-[#fff] text-[#188ae2] hover:bg-[#188ae2] hover:text-[#fff] 
          border border-[#188ae2]  rounded-lg px-4 py-2 mt-4" onClick={handleUpdate}> Update </button>
              : <button type='button' className="mx-auto block bg-[#fff] text-[#188ae2] hover:bg-[#188ae2] hover:text-[#fff] 
          border border-[#188ae2]  rounded-lg px-4 py-2 mt-4" onClick={handleSubmit}> Submit </button>}

            <button type='button' className="mx-auto block bg-[#fff] text-[#188ae2] hover:bg-[#188ae2] hover:text-[#fff]
           border border-[#188ae2] rounded-lg px-4 py-2 mt-4" onClick={() => { setData(initialValue) }}>Reset</button>
            <button type='button' className="mx-auto block bg-[#fff] text-[#188ae2] hover:bg-[#188ae2] hover:text-[#fff]
           border border-[#188ae2] rounded-lg px-4 py-2 mt-4" onClick={() => { setData(initialValue) }}>Cancle</button>
          </div>
        </div>
      </form>

      <div className="w-full h-screen ">
        <div className="max-w-8xl mx-auto sm:px-6 lg:px-8">
          <div className="flex flex-col">

            <div className="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 my-10">
              <div className="align-middle inline-block w-full  shadow-xl overflow-x-auto sm:rounded-lg border border-gray-400 ">
                <table className="min-w-full text-center">
                  <thead className='text-center'>
                    <tr className="bg-gray-50  text-xs leading-4 text-gray-500 uppercase tracking-wider  border-b border-gray-400">

                      <th className="px-6 py-3 font-medium bg-gray-200  border-r border-gray-400">
                        ID
                      </th>
                      <th className="px-6 py-3 font-medium bg-gray-200  border-r border-gray-400">
                        Blog Name
                      </th>
                      <th className="px-6 py-3 font-medium bg-gray-200  border-r border-gray-400">
                        Blog Description
                      </th> <th className="px-6 py-3 font-medium bg-gray-200  border-r border-gray-400">
                        Blog Image
                      </th>
                      <th className="px-6 py-3 font-medium bg-gray-200">
                        Edit
                      </th>

                    </tr>
                  </thead>

                  <tbody className="bg-white text-center">

                    {allDataTable.map((item, i) => {

                      return <>
                        <tr className='border border-grey-400'>
                          <td className='text-black border-r border-gray-400'>{i + 1}</td>
                          <td className="px-6 py-4 whitespace-no-wrap  bg-white-200  border-r border-gray-400">
                            <div className="text-sm leading-5 text-gray-900">
                              {item.blog_name}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap  bg-white-200  border-r border-gray-400">
                            <div className="text-sm leading-5 text-gray-900">
                              {item.blog_description}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap  bg-white-200  border-r border-gray-400">
                            <div className="text-sm leading-5 text-gray-900">

                              <img

                                src={`https://hapi.hindustanrides.in/uploads/blog/${item.blog_img}`}
                                alt="Blog Image"
                                className="h-30 w-40"
                              />

                            </div>
                          </td>

                          <td className="px-6 py-4 whitespace-no-wrap bg-white-200 text-center">
                            <div className="text-sm leading-5 text-gray-900 flex space-x-6 justify-center items-center">
                              <RiFileEditFill size={20} fill='green' onClick={() => getUpdateData(item._id)} />

                              <RiDeleteBin6Fill size={20} fill='red' onClick={() => handleDelete(item._id)} />
                            </div>
                          </td>
                        </tr>
                      </>
                    })}

                  </tbody>

                </table>
              </div>
            </div>
          </div>
        </div>
      </div>


    </main>
  </>
}
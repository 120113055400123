import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Header from './Component/Header';
import Sidebar from './Component/Sidebar';
import Home from './Component/Home';  
import CarForm from './Forms/carForm';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CityForm from './Forms/cityForm';
import CarDetailsForm from './Forms/carDetail';
import Memories from './Forms/Memories';
import CarCabForm from './Forms/carCabForm';
import TourForm from './Forms/tourForm';
import ContactForm from './Forms/ContactDetail';
import BookingDetails from './Forms/BookingDetails';
import Login from './Component/Login';
import LocalDropCity from './Forms/Local_DropCityForm';
import LocalDropCityDetails from './Forms/Local_dropCityDetail';
import Blog from './Forms/blog';
import CarDetailsHindustanRentalTaxi from './Forms/CarDetailsHindustanRentalTaxi'

function App() {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);
  if(window.location.pathname === '/'){
    localStorage.removeItem("token");
  }
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setLoggedIn(true);
    }
  }, []); 

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  }

  return (
    <>

      <Router>
        {isLoggedIn ? (
          <div className='grid-container'>
            <Header OpenSidebar={OpenSidebar}  />
            <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} setOpenSidebarToggle={setOpenSidebarToggle}/>
            <Routes>
              <Route path="/dashboard" element={<Home />} />
              <Route path="/car-form" element={<CarForm />} />
              <Route path="/city-form" element={<CityForm />} />
              <Route path='/car-details-Form' element={<CarDetailsForm />} />
              <Route path='/car-memories' element={<Memories />} />
              <Route path='/car-cab-collection' element={<CarCabForm />} />
              <Route path='/tour' element={<TourForm />} />
              <Route path='/booking-detail' element={<BookingDetails />} />
              <Route path='/contact-detail' element={<ContactForm />} />
              <Route path='/local-dropcity-form' element={<LocalDropCity />} />
              <Route path='/local-dropcity-detail' element={<LocalDropCityDetails />} />
              <Route path='/blog' element={<Blog />} />
              <Route path='/carDetails' element={<CarDetailsHindustanRentalTaxi />} />
            </Routes>
            <ToastContainer closeButton={false} position="bottom-right" />
          </div>
        ) : (
          <>
            <Routes>
              <Route path="/" element={<Login setLoggedIn={setLoggedIn} />} />
            </Routes>
           
          </>
        )}
         <ToastContainer closeButton={false} position="bottom-right" />
      </Router>
    </>
  );
}

export default App;


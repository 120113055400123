import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { toast, Zoom } from "react-toastify";
import { Icon } from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye'

export default function Login({ setLoggedIn }) {
    const [password, setPassword] = useState("");
    const [type, setType] = useState('password');
    const [icon, setIcon] = useState(eyeOff);

    const navigate = useNavigate();
    const initialValue = {
        email: "",
        password: ""
    }
    const [data, setData] = useState(initialValue);
    const handleChange = (e) => {
        const name = e.target.name;
        setData({ ...data, [name]: e.target.value })
    }
    const handleSubmit = async (e) => {
        try {
            const addRecordEndpoint = `https://hapi.hindustanrides.in/api/v1/login`;
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }
            const response = await fetch(addRecordEndpoint, options);
            const jsonResponse = await response.json();
            if (jsonResponse) {
                setData(jsonResponse);
                setData(initialValue);
            }
            if (jsonResponse.status === "success") {

                setLoggedIn(true);
                localStorage.setItem("token", jsonResponse.token)
                navigate('/dashboard');
                toast.success(jsonResponse.message);


            }
            if (jsonResponse.status === "fail") {
                toast.error(jsonResponse.message);
            }
        } catch (err) {
            // toast.error("Please Enter valid");
        }

    }
    const handleToggle = () => {
        if (type === 'password') {
            setIcon(eye);
            setType('text')
        } else {
            setIcon(eyeOff)
            setType('password')
        }
    }

    return <>
        <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
            <div className="relative py-3 sm:max-w-xl sm:mx-auto">
                <div className="absolute inset-0 bg-gradient-to-r from-blue-300 to-blue-600 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl">
                </div>
                <div className="relative px-4 py-10 bg-white shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] sm:rounded-3xl sm:px-20">
                    <div className="max-w-md mx-auto">
                        <div className='mb-5'>
                            <h1 className="text-3xl font-semibold flex space-x-2">
                                <p className='text- text-[#7dace1]'>HINDUSTAN</p>
                                <p className='text-[#2563eb]'>RIDES</p>
                            </h1>
                            <p className='text-[12px] text-right text-[#2563eb] font-bold'>PERFECT TIME , PERFECT PLACE</p>
                        </div>
                        <div className="divide-y divide-gray-200">
                            <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                                <div className="relative">
                                    <input id="email" name="email" type="text" className="peer text-[18px] placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                        placeholder="Email address" value={data.email} onChange={handleChange} />
                                    <label htmlFor="email" className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                                        Email Address</label>
                                </div>
                                <div className="relative">
                                    <input id="password" type={type} name="password" className="peer ext-[18px] placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                                        placeholder="Password" value={data.password} onChange={handleChange} />



                                    <label htmlFor="password" className="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Password</label>
                                    <span className="flex justify-around items-center mt-[-20px]  ml-[245px]" onClick={handleToggle}>
                                        <Icon className="absolute mr-10" icon={icon} size={25} />
                                    </span>
                                </div>

                                <div className="flex justify-center items-center ">
                                    <button type='submit' className=" border-2 border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white rounded-md px-5 py-1 mt-10" 
                                    onClick={handleSubmit}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}
import React, { useEffect, useState } from 'react';
import { toast, Zoom } from "react-toastify";
import { RiFileEditFill, RiDeleteBin6Fill } from 'react-icons/ri';
import axios from 'axios';

const initialValue = {
  rate: "",
  bootspace: "",
  car_img: [],
  icon: [],
  car_name: "",
  isAcCar: "",
  car_type: "",
  seats: "",
  roundtrip_rate: ""
};

export default function CarDetailsForm() {
  const [data, setData] = useState(initialValue);
  const [taxiPrint, setTaxiPrint] = useState([]);
  const [edit, setEdit] = useState(false);
  const [selectPrint, setSelectPrint] = useState("");
  const [formData, setFormData] = useState(initialValue);
  const [showImg, setShowImg] = useState([]);
  const [showImgIcon, setShowImgIcon] = useState([]);
  const [allDataTable, setAllDataTable] = useState([]);

  // Form submit.....
  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('car_name', selectPrint);
      formDataToSend.append('rate', formData.rate);
      formDataToSend.append('bootspace', formData.bootspace);
      formDataToSend.append('isAcCar', formData.isAcCar);
      formDataToSend.append('car_type', formData.car_type);
      formDataToSend.append('seats', formData.seats);
      formDataToSend.append('roundtrip_rate', formData.roundtrip_rate);


      for (let i = 0; i < formData.car_img.length; i++) {
        formDataToSend.append('car_img', formData.car_img[i]);
      }
      for (let i = 0; i < formData.icon.length; i++) {
        formDataToSend.append('icon', formData.icon[i]);
      }

      const response = await axios.post(`https://bhadegadiapi.taxiwalataxi.in/api/v1/addtaxidetails`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.status === "success") {
        toast.success(response.data.message);
        setFormData(initialValue);
        setSelectPrint("");
        setShowImg(null);
        window.location.reload();
      }
      if (response.data.status === "fail") {
        toast.error(response.data.message);
      }

    } catch (err) {
      console.log(err);
    }
  };

  const fatchData = async () => {
    try {
      const addRecordEndpoint = "https://bhadegadiapi.taxiwalataxi.in/api/v1/getTaxidetails";
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      };

      const response = await fetch(addRecordEndpoint, options);
      const jsonResponse = await response.json();
      setAllDataTable(jsonResponse?.result);
    } catch (err) {
      console.log(err);
    }
  };

  const fatch = async () => {
    try {
      const addRecordEndpoint = "https://hapi.hindustanrides.in/api/v1/allTaxi";
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      };
      const response = await fetch(addRecordEndpoint, options);
      const jsonResponse = await response.json();
      setTaxiPrint(jsonResponse?.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fatch();
    fatchData();
  }, []);

  const onOptionChangeHandler = (event) => {
    setSelectPrint(event.target.value);
  };

  const handlechange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    const files = e.target.files;
    setFormData({
      ...formData,
      car_img: [...files],
    });

    if (files.length > 0) {
      setShowImg(URL.createObjectURL(files[0]));
    }
  };

  const handleImageChangeIcon = (e) => {
    const files = e.target.files;
    setFormData({
      ...formData,
      icon: [...files],
    });

    if (files.length > 0) {
      setShowImgIcon(URL.createObjectURL(files[0]));
    }
  };

  const handleUpdate = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('car_name', selectPrint);
      formDataToSend.append('rate', formData.rate);
      formDataToSend.append('bootspace', formData.bootspace);
      formDataToSend.append('isAcCar', formData.isAcCar);
      formDataToSend.append('car_type', formData.car_type);
      formDataToSend.append('seats', formData.seats);
      formDataToSend.append('roundtrip_rate', formData.roundtrip_rate);


  
      // Append car_img if it's an array or single image
      if (formData.car_img) {
        if (Array.isArray(formData.car_img)) {
          formData.car_img.forEach((img) => {
            formDataToSend.append('car_img', img);
          });
        } else {
          formDataToSend.append('car_img', formData.car_img); // Single image
        }
      }
  
      // Append icon if it's an array or single icon
      if (formData.icon) {
        if (Array.isArray(formData.icon)) {
          formData.icon.forEach((icon) => {
            formDataToSend.append('icon', icon);
          });
        } else {
          formDataToSend.append('icon', formData.icon); // Single icon
        }
      }
  
      const response = await axios.patch(
        `https://bhadegadiapi.taxiwalataxi.in/api/v1/updatetaxidetail/${formData.id}`,
        formDataToSend,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
  
      if (response.data.status === 'success') {
        toast.success(response.data.message);
        setFormData(initialValue);
        setSelectPrint("");
        fatchData();
        setShowImg(null);
        setShowImgIcon(null);
        setEdit(false);
        // Consider refreshing data or UI state instead of reloading the entire page
        // window.location.reload();
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      console.log(err);
      toast.error('Error updating item. Please try again.');
    }
  };
  
  
  


  // Update get data form.....
  const getUpdateData = (id) => {
    console.log(id);
    const user = allDataTable.find((user) => user._id === id);
    console.log(user.car_img);

    setFormData({
      ...initialValue,
      bootspace: user.bootspace,
      car_type: user.car_type,
      isAcCar: user.isAcCar,
      rate: user.rate,
      seats: user.seats,
      car_img: user.car_img,
      icon: user.icon,
      roundtrip_rate: user.roundtrip_rate,
      id: user._id
    });

    setShowImg(`https://bhadegadiapi.taxiwalataxi.in/uploads/${user.car_img}`);
    setShowImgIcon(`https://bhadegadiapi.taxiwalataxi.in/uploads/${user.icon}`);

    setSelectPrint(user.car_name);
    setEdit(true);
  };

  // Delete products.....
  const handleDelete = id => {
    console.log(id);
    try {
      fetch(`https://bhadegadiapi.taxiwalataxi.in/api/v1/deletetaxidetail/${id}`, {
        method: "DELETE"
      })
        .then(response => response.json())
        .then(() => {
          setAllDataTable(values => {
            return values.filter(item => item._id !== id);
          });
        });
      fatchData();
      toast.success("Delete Taxi Successfully");
    } catch (err) {
      console.log(err);
    }
  };

  return <>
    <main className='main-container'>
      <h1 className='text-black text-[20px] py-5 px-8'> Car Details Hindustan Rental Taxi </h1>
      <form>
        <div className="w-full max-w-4xl mx-auto py-4 bg-gray-200 my-4 px-4 shadow-xl rounded-xl grid grid-cols-2 space-x-6">

          <div className='pt-2 ml-6'>
            <select onChange={onOptionChangeHandler} value={selectPrint} className=' w-full border border-gray-400 p-2.5  focus:outline-none rounded-md text-[#000]'>
              <option className='text-[#000]'>Taxi Name </option>
              {taxiPrint?.map((option, index) => {
                return (
                  <option key={index} name="car_name" value={option?.taxi_name} className='text-[#000]'>
                    {option?.taxi_name}
                  </option>
                );
              })}
            </select>
          </div>

          <div className='pt-2 ml-6'>
  <select
    name="isAcCar"
    onChange={handlechange}
    value={formData.isAcCar}
    className='w-full border border-gray-400 p-2.5 focus:outline-none rounded-md text-[#000]'
  >
    <option value="">Taxi AC</option>
    <option value="AC">AC</option>
    <option value="Non AC">Non AC</option>
  </select>
</div>

<div className='pt-2 ml-6'>
  <select
    name="car_type"
    onChange={handlechange}
    value={formData.car_type}
    className='w-full border border-gray-400 p-2.5 focus:outline-none rounded-md text-[#000]'
  >
    <option value="">Type</option>
    {/* <option value="Sedan">Sedan</option>

    <option value="Traveller">Traveller </option>
   
    <option value="Crysta">Crysta</option>
    <option value="Ertiga">Ertiga</option>
    <option value="Winger">Winger</option>
    <option value="Urbania">Urbania</option> */}
    <option value="Sedan">Sedan</option>
    {/* <option value="Hatchback">Hatchback</option> */}
    <option value="Crysta">Crysta</option>
    <option value="17-Seat">17-Seat </option>
    {/* <option value="Bus">Bus</option> */}
    {/* <option value="Mini Bus">Mini Bus</option> */}
    <option value="14-Seat">14-Seat </option>
    <option value="20-Seat ">20-Seat </option>
    <option value="Winger">Winger</option>
    <option value="Urbania">Urbania</option>

    {/* <option value="EECO">EECO</option> */}
    {/* <option value="Premium Car">Premium Car</option> */}
    {/* <option value="Only Parcel">Only Parcel</option> */}
  </select>
</div>

          
          <div className=" py-2 ">
            <label className='text-gray-600 font-bold mr-2 mt-2'>Car Image</label>
            <input
              type="file"
              name="car_img"
              className="w-80 border border-gray-300 rounded-lg px-4 py-2 focus:outline-none text-[#000] focus:border-blue-500"
              placeholder="Enter product image"
              onChange={handleImageChange}
              multiple
              accept="image/*"
            />
           {edit ? <img src={showImg} className='h-24 w-40'/> : ""}
          </div>
          <div className=" py-2">
            <input className="w-full border border-gray-400 p-2 focus:outline-none rounded-md text-black"
              type="number" placeholder="Seats" name='seats' onChange={handlechange} value={formData.seats} />
          </div>
          <div className=" py-2">
          <label className='text-gray-600 font-bold mr-2 mt-2'>Icon Image</label>
            <input
              type="file"
              name="icon"
              className="w-[310px] border border-gray-300 rounded-lg px-4 py-2 focus:outline-none text-[#000] focus:border-blue-500"
              placeholder="Enter product image"
              onChange={handleImageChangeIcon}
              multiple
              accept="image/*"
            />
           {edit ? <img src={showImgIcon} className='h-24 w-40'/> : ""}
          </div>

          <div className=" py-2">
            <input className="w-full border border-gray-400 p-2 focus:outline-none rounded-md text-black"
              type="number" placeholder="OneWay Rate" name='rate' onChange={handlechange} value={formData.rate} />
          </div>
         
          <div className=" py-2">
            <input className="w-full border border-gray-400 p-2 focus:outline-none rounded-md text-black"
              type="number" placeholder="bootspace" name='bootspace' onChange={handlechange} value={formData.bootspace} />
          </div>
          <div className=" py-2">
            <input className="w-full border border-gray-400 p-2 focus:outline-none rounded-md text-black"
              type="number" placeholder="RoundTrip Rate" name='roundtrip_rate' onChange={handlechange} value={formData.roundtrip_rate} />
          </div>
          <div></div>

    
          <div className='flex justify-center'>
            {edit ? <button type='button' className="mx-auto block bg-[#fff] text-[#188ae2] hover:bg-[#188ae2] hover:text-[#fff] 
          border border-[#188ae2]  rounded-lg px-4 py-2 mt-4" onClick={handleUpdate}> Update </button>
              : <button type='button' className="mx-auto block bg-[#fff] text-[#188ae2] hover:bg-[#188ae2] hover:text-[#fff] 
          border border-[#188ae2]  rounded-lg px-4 py-2 mt-4" onClick={handleSubmit}> Submit </button>}

            <button type='button' className="mx-auto block bg-[#fff] text-[#188ae2] hover:bg-[#188ae2] hover:text-[#fff]
           border border-[#188ae2] rounded-lg px-4 py-2 mt-4" onClick={() => { setData(initialValue) }}>Reset</button>
            <button type='button' className="mx-auto block bg-[#fff] text-[#188ae2] hover:bg-[#188ae2] hover:text-[#fff]
           border border-[#188ae2] rounded-lg px-4 py-2 mt-4" onClick={() => { setData(initialValue) }}>Cancle</button>
          </div>
        </div>
      </form>
      <div className="w-full h-screen ">
        <div className="max-w-8xl mx-auto sm:px-6 lg:px-8">
          <div className="flex flex-col">

            <div className="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 my-10">
              <div className="align-middle inline-block w-full  shadow-xl overflow-x-auto sm:rounded-lg border border-gray-400 ">
                <table className="min-w-full text-center">
                  <thead className='text-center'>
                    <tr className="bg-gray-50  text-xs leading-4 text-gray-500 uppercase tracking-wider  border-b border-gray-400">

                      <th className="px-6 py-3 font-medium bg-gray-200  border-r border-gray-400">
                        ID
                      </th>
                     
                      <th className="px-6 py-3 font-medium bg-gray-200  border-r border-gray-400">
                        Taxi Name
                      </th>
                      <th className="px-6 py-3 font-medium bg-gray-200  border-r border-gray-400">
                        Taxi AC
                      </th>

                      <th className="px-6 py-3 font-medium bg-gray-200  border-r border-gray-400">
                        Type
                      </th>
                      <th className="px-6 py-3 font-medium bg-gray-200  border-r border-gray-400">
                        Car Image
                      </th>
                      <th className="px-6 py-3 font-medium bg-gray-200  border-r border-gray-400">
                        Seats
                      </th>
                      <th className="px-6 py-3 font-medium bg-gray-200  border-r border-gray-400">
                        Icon Image
                      </th>
                      <th className="px-6 py-3 font-medium bg-gray-200  border-r border-gray-400">
                      OneWay Rate
                      </th>
                      <th className="px-6 py-3 font-medium bg-gray-200  border-r border-gray-400">
                      RoundTrip Rate
                      </th>
                      <th className="px-6 py-3 font-medium bg-gray-200  border-r border-gray-400">
                      Bootspace
                      </th>
                      <th className="px-6 py-3 font-medium bg-gray-200">
                        Edit
                      </th>

                    </tr>
                  </thead>

                  <tbody className="bg-white text-center">

                    { allDataTable.map((item, i) => {
                      return <>
                        <tr className='border border-grey-400'>
                          <td className='text-black border-r border-gray-400'>{i + 1}</td>
                          
                          <td className="px-6 py-4 whitespace-no-wrap  bg-white-200  border-r border-gray-400">
                            <div className="text-sm leading-5 text-gray-900">
                              {item.car_name}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap  bg-white-200  border-r border-gray-400">
                            <div className="text-sm leading-5 text-gray-900">
                              {item.isAcCar}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap  bg-white-200  border-r border-gray-400">
                            <div className="text-sm leading-5 text-gray-900">
                              {item.car_type}
                            </div>
                          </td>
                          <td className=" whitespace-no-wrap  bg-white-200  border-r border-gray-400">
                            <div className="text-sm leading-5 text-gray-900">
                              <img src={`https://bhadegadiapi.taxiwalataxi.in/uploads/${item.car_img}`} className='h-[100px] w-[100px] object-contain' />
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap  bg-white-200  border-r border-gray-400">
                            <div className="text-sm leading-5 text-gray-900">
                              {item.seats}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap  bg-white-200  border-r border-gray-400">
                            <div className="text-sm leading-5 text-gray-900">
                              <img src={`https://bhadegadiapi.taxiwalataxi.in/uploads/${item.icon}`} className='h-[100px] w-[100px]' />
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap  bg-white-200  border-r border-gray-400">
                            <div className="text-sm leading-5 text-gray-900">
                              {item.rate}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap  bg-white-200  border-r border-gray-400">
                            <div className="text-sm leading-5 text-gray-900">
                              {item.roundtrip_rate}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap  bg-white-200  border-r border-gray-400">
                            <div className="text-sm leading-5 text-gray-900">
                              {item.bootspace}
                            </div>
                          </td>
                         
                          
                          <td className="px-6 py-4 whitespace-no-wrap bg-white-200 text-center">
                            <div className="text-sm leading-5 text-gray-900 flex space-x-6 justify-center items-center">
                              <RiFileEditFill size={20} fill='green' onClick={() => getUpdateData(item._id)} />

                              <RiDeleteBin6Fill size={20} fill='red' onClick={() => handleDelete(item.id)} />
                            </div>
                          </td>
                        </tr>
                      </>
                    })}

                  </tbody>

                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </>
}
import React, { useEffect, useState } from 'react';

const ContactForm = () => {
  const [printData, setPrintData] = useState([]);

  const fetchContactData = async () => {
    try {
      const addRecordEndpoint = "https://hapi.hindustanrides.in/api/v1/alldetail";

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      };

      const response = await fetch(addRecordEndpoint, options);
      const jsonResponse = await response.json();
      setPrintData(jsonResponse?.data);

    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchContactData();
  }, []);

  return <>

    <main className='main-container'>
    <h1 className='text-black text-[20px] py-3 px-8'>Contacts Details</h1>

    <div className="w-full h-screen ">
        <div className="max-w-6xl mx-auto sm:px-6 lg:px-8">
          <div className="flex flex-col">

            <div className="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 my-10">
              <div className="align-middle inline-block w-full  shadow-xl overflow-x-auto sm:rounded-lg border border-gray-400 ">
                <table className="min-w-full text-center">

                <thead className='text-center'>
                  <tr className="bg-gray-50 text-xs leading-4 text-gray-500 uppercase tracking-wider border-b border-gray-400">
                    <th className="px-6 py-3 font-medium bg-gray-200 border-r border-gray-400">
                      ID
                    </th>
                    <th className="px-6 py-3 font-medium bg-gray-200 border-r border-gray-400">
                      Name
                    </th>
                    <th className="px-6 py-3 font-medium bg-gray-200 border-r border-gray-400">
                      Phone No
                    </th>
                    <th className="px-6 py-3 font-medium bg-gray-200 border-r border-gray-400">
                      Email
                    </th>
                    <th className="px-6 py-3 font-medium bg-gray-200 border-r border-gray-400">
                      Message
                    </th>
                  </tr>
                </thead>

                <tbody className="bg-white text-center">
                  {printData?.map((item, i) => (
                    <tr key={i} className='border border-grey-400'>
                      <td className='text-black border-r border-gray-400'>{i + 1}</td>
                      <td className="px-6 py-4 whitespace-no-wrap bg-white-200 border-r border-gray-400">
                        <div className="text-sm leading-5 text-gray-900">
                          {item.name}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap bg-white-200 border-r border-gray-400">
                        <div className="text-sm leading-5 text-gray-900">
                          {item.phoneno}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap bg-white-200 border-r border-gray-400">
                        <div className="text-sm leading-5 text-gray-900">
                          {item.email}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap bg-white-200 border-r border-gray-400">
                        <div className="text-sm leading-5 text-gray-900">
                          {item.message}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
</main>

</>
};

export default ContactForm;

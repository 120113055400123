
import React,{useState,useEffect} from 'react'
import { BsFillArchiveFill, BsFillGrid3X3GapFill, BsPeopleFill, BsFillBellFill }
  from 'react-icons/bs'
import { BiSolidHome } from 'react-icons/bi';
import { HiLocationMarker } from 'react-icons/hi';
import { AiFillCar, AiFillCheckCircle } from 'react-icons/ai';
import CountUp from "react-countup";


function Home() {
  const [booking, setBooking] = useState();
  const [contact, setContact] = useState();
  const [cars, setCars] = useState();
  const [city, setCity] = useState();



  const fetchContactData = async () => {
    try {
      //booking
      const addRecordEndpointBooking = "https://hapi.hindustanrides.in/api/v1/allBooking";
      const optionsBooking = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      };
      const responseBoooking = await fetch(addRecordEndpointBooking, optionsBooking);
      const jsonResponseBooking = await responseBoooking.json();
      setBooking(jsonResponseBooking?.data.length);
    
      //Contact
      const addRecordEndpointContact = "https://hapi.hindustanrides.in/api/v1/alldetail";
      const optionsContact = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      };
      const responseContact = await fetch(addRecordEndpointContact, optionsContact);
      const jsonResponseContact = await responseContact.json();
      setContact(jsonResponseContact?.data.length);
      

      //cars
      const addRecordEndpointCars= "https://hapi.hindustanrides.in/api/v1/allTaxi";
      const optionsCars = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      };
      const responseCars = await fetch(addRecordEndpointCars, optionsCars);
      const jsonResponseCars = await responseCars.json();
      setCars(jsonResponseCars?.data.length);


      //city
      const addRecordEndpointCity = "https://hapi.hindustanrides.in/api/v1/allCity";
      const optionsCity = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      };
      const responseCity = await fetch(addRecordEndpointCity, optionsCity);
      const jsonResponseCity = await responseCity.json();
      setCity(jsonResponseCity?.data.length);

    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    fetchContactData();
  }, []);
  return (
    <main className='main-container'>
      <div className='p-5'>
        <div className='main-cards'>
          <div className='card'>
            <div className='card-inner'>
              <h3 className='text-[25px]'>BOOKING</h3>
              <BsFillArchiveFill className='card_icon' />
            </div>
            <h1 className='text-[40px]'>
            <CountUp
                    start={0}
                    end={booking}
                    duration={3}
                /></h1>
          </div>
          <div className='card'>
            <div className='card-inner'>
              <h3  className='text-[25px]'>CONTACT</h3>
              <BsFillGrid3X3GapFill className='card_icon' />
            </div>
            <h1  className='text-[40px]'> <CountUp
                    start={0}
                    end={contact}
                    duration={3}
                /></h1>
          </div>
          <div className='card'>
            <div className='card-inner'>
              <h3  className='text-[25px]'>CARS</h3>
              <BsPeopleFill className='card_icon' />
            </div>
            <h1  className='text-[40px]'> <CountUp
                    start={0}
                    end={cars}
                    duration={3}
                /></h1>
          </div>
          <div className='card'>
            <div className='card-inner'>
              <h3  className='text-[25px]'>CITYS</h3>
              <BsFillBellFill className='card_icon' />
            </div>
            <h1 className='text-[40px]'> <CountUp
                    start={0}
                    end={city}
                    duration={3}
/></h1>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Home

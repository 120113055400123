import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import { RiFileEditFill, RiDeleteBin6Fill } from 'react-icons/ri';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import ReactMarkdown from 'react-markdown';
import { draftToMarkdown } from 'markdown-draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const initialValue = {
  tour_name: "",
  tour_description: EditorState.createEmpty(),
  tourImg: []
};

export default function TourForm() {
  const [data, setData] = useState(initialValue);
  const [printData, setPrintData] = useState([]);
  const [edit, setEdit] = useState(false);
  const [showImg, setShowImg] = useState();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`https://hapi.hindustanrides.in/api/v1/deletetour/${id}`, {
        method: "DELETE"
      });
      if (response.ok) {
        setPrintData(values => values.filter(item => item.id !== id));
        toast.success("Delete Tour Successfully");
      }
    } catch (err) {
      console.error(err);
      toast.error("Failed to delete Tour");
    }
  };

  const handleUpdate = async () => {
    try {
      const addRecordEndpoint = `https://hapi.hindustanrides.in/api/v1/edittour/${data._id}`;
      const formDataImg = new FormData();

      for (let i = 0; i < data.tourImg.length; i++) {
        formDataImg.append('tourImg', data.tourImg[i]);
      }
      formDataImg.append('tour_name', data.tour_name);
      formDataImg.append('tour_description', draftToMarkdown(convertToRaw(data.tour_description.getCurrentContent())));

      const response = await fetch(addRecordEndpoint, {
        method: 'PATCH',
        body: formDataImg,
      });

      const jsonResponse = await response.json();
      if (jsonResponse.status === "success") {
        setEdit(false);
        setData(initialValue);
        toast.success('Update Tour Data Successfully');
        fetchPrintData(); // Fetch updated data
      }
    } catch (error) {
      console.error(error);
      toast.error('Failed to update Tour data');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const addRecordEndpoint = 'https://hapi.hindustanrides.in/api/v1/addtour';
      const formDataImg = new FormData();
      for (let i = 0; i < data.tourImg.length; i++) {
        formDataImg.append('tourImg', data.tourImg[i]);
      }
      formDataImg.append('tour_name', data.tour_name);
      formDataImg.append('tour_description', draftToMarkdown(convertToRaw(data.tour_description.getCurrentContent()))); // Add tour_description here

      const response = await fetch(addRecordEndpoint, {
        method: 'POST',
        body: formDataImg,
      });
  
      const jsonResponse = await response.json();
      if (jsonResponse.status === "success") {
        toast.success(jsonResponse.message);
        fetchPrintData(); // Fetch updated data
        setData(initialValue);
      }
    } catch (err) {
      console.error(err);
      toast.error('Failed to add Tour');
    }
  };
  
  const fetchPrintData = async () => {
    try {
      const addRecordEndpoint = "https://hapi.hindustanrides.in/api/v1/gettour";

      const response = await fetch(addRecordEndpoint);
      const jsonResponse = await response.json();

      setPrintData(jsonResponse?.data);
    } catch (err) {
      console.error(err);
      toast.error('Failed to fetch tour data');
    }
  };

  useEffect(() => {
    fetchPrintData();
  }, []);

  const updateUser = async (id) => {
    try {
        const user = printData.find((user) => user._id === id);
        const image = `https://hapi.hindustanrides.in/uploads/${user.tourImg}`;
        setShowImg(image);
        const contentState = ContentState.createFromText(user.tour_description);
        const editorState = EditorState.createWithContent(contentState);
        setData(user);
        setData(prevState => ({...prevState, tour_description: editorState}));
        setEdit(true);
    } catch (error) {
        console.error(error);
        // Add error handling logic here
    }
};


  return (
    <>
      <main className='main-container'>
        <h1 className='text-black text-[20px] py-3 px-8'>Best Of Our Memories Form.</h1>
        <form onSubmit={handleSubmit}>
          <div className="w-full max-w-xl mx-auto py-4 bg-gray-200 my-4 px-4 shadow-xl rounded-xl">
            <div className="py-2">
              <input
                className="w-full border border-gray-400 p-2 focus:outline-none rounded-md text-black"
                type="text"
                name='tour_name'
                placeholder='Tour Name'
                onChange={handleChange}
                value={data.tour_name}
              />
            </div>
            <div className="py-2">
              <Editor
                editorStyle={{ border: "1px solid grey " ,borderRadius:'5px', minHeight: "auto", color: "black" }}
                wrapperClassName="wrapper"
                editorClassName="editor"
                toolbarClassName="toolbar"
                placeholder='Tour Details'
                editorState={data.tour_description}
                onEditorStateChange={(editorState) => setData({ ...data, tour_description: editorState })}
              />
            </div>
            <div className="py-2">
              <input
                className="w-full border border-gray-400 p-2 focus:outline-none rounded-md text-black"
                type="file"
                name='tourImg'
                onChange={(e) => setData({ ...data, tourImg: [...e.target.files] })}
              />
              {edit && <img src={showImg} alt="Tour" />}
              
            </div>
            <div className='flex'>
              {edit ? <button type='button' className="mx-auto block bg-[#fff] text-[#188ae2] hover:bg-[#188ae2] hover:text-[#fff] 
                border border-[#188ae2]  rounded-lg px-4 py-2 mt-4" onClick={handleUpdate}> Update </button>
                : <button type='submit' className="mx-auto block bg-[#fff] text-[#188ae2] hover:bg-[#188ae2] hover:text-[#fff] 
                border border-[#188ae2]  rounded-lg px-4 py-2 mt-4"> Submit </button>}

              <button type='button' className="mx-auto block bg-[#fff] text-[#188ae2] hover:bg-[#188ae2] hover:text-[#fff]
                border border-[#188ae2] rounded-lg px-4 py-2 mt-4" onClick={() => setData(initialValue)}>Reset</button>
            </div>
            
          </div>
        </form>
        <div className="w-full h-screen ">
          <div className="max-w-6xl mx-auto sm:px-6 lg:px-8">
            <div className="flex flex-col">
              <div className="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 my-10">
                <div className="align-middle inline-block w-full  shadow-xl overflow-x-auto sm:rounded-lg border border-gray-400 ">
                  <table className="min-w-full text-center bg-black">
                    <thead className='text-center'>
                      <tr className="bg-gray-50  text-xs leading-4 text-gray-500 uppercase tracking-wider  border-b border-gray-400">
                        <th className="px-6 py-3 font-medium bg-gray-200  border-r border-gray-400">
                          ID
                        </th>
                        <th className="px-6 py-3 font-medium bg-gray-200  border-r border-gray-400">
                          Tour Name
                        </th>
                        <th className="px-6 py-3 font-medium bg-gray-200  border-r border-gray-400">
                          Tour Description
                        </th>
                        <th className="px-6 py-3 font-medium bg-gray-200  border-r border-gray-400">
                          Tour Image
                        </th>
                        <th className="px-6 py-3 font-medium bg-gray-200">
                          Edit
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white text-center">
                      {printData?.map((item, i) => {
                        return (
                          <tr key={i} className='border border-grey-400 text-[12px]'>
                            <td className='text-black border-r border-gray-400'>{i + 1}</td>
                            <td className='text-[#000] text-black border-r border-gray-400'>{item.tour_name}</td>
                            <td className='text-black border-r border-gray-400'>
                              <ReactMarkdown className='text-black  px-3 py-2'>{item.tour_description}</ReactMarkdown>
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap  bg-white-200  border-r border-gray-400">
                              <div className="text-sm leading-5 text-gray-900">
                                <img src={`https://hapi.hindustanrides.in/uploads/${item.tourImg}`} className='w-full h-full' alt="Tour" />
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap bg-white-200 text-center">
                              <div className="text-sm leading-5 text-gray-900 flex space-x-6 justify-center items-center">
                                <RiFileEditFill size={20} fill='green' onClick={() => updateUser(item._id)} />
                                <RiDeleteBin6Fill size={20} fill='red' onClick={() => handleDelete(item._id)} />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

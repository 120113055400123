import React from 'react';
import { useNavigate } from "react-router-dom";
import {BsFillBellFill, BsFillEnvelopeFill, BsPersonCircle, BsSearch, BsJustify}
 from 'react-icons/bs'
import { toast } from 'react-toastify';

function Header({OpenSidebar}) {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate('/');
    toast.success("Logout successfully");
    window.location.reload();
  }
  return (
    <header className='header bg-[#188ae2]'>
        <div className='menu-icon'>
            <BsJustify size={20} fill='#fff' onClick={OpenSidebar}/>
        </div>
        <div className='header-left'>
           <h1 className='text-white text-[20px] '>Dashboard</h1>
        </div>
        <div className='header-right flex space-x-8'>
            {/* <BsFillBellFill size={20} fill='white'/>
            <BsFillEnvelopeFill size={20} fill='white'/>
            <BsPersonCircle size={20} fill='white'/> */}
            <button className='border-2 text-[#fff] hover:bg-[#fff] hover:text-[#188ae2] px-5 py-1 rounded-xl shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]' onClick={handleLogout} >Logout</button>
        </div>
        {/* <div className="flex ml-auto ">
               <ul className="flex space-x-8">
                 <li className="mr-2 hidden sm:inline-block">
                   <FaSearch size={19} fill='#fff' />
                </li>
                 <li className="mr-2 hidden sm:inline-block">
                  <IoMdNotifications size={22} fill='#fff' />
                 </li>
                 <li className="mr-2 hidden sm:inline-block">
                  <AiFillSetting size={22} fill='#fff' />
                 </li>
                <li className="mr-2 hidden sm:inline-block">
                 <FaUserCircle size={22} fill='#fff' />
                </li>
              </ul>
           </div> */}
    </header>
  )
}

export default Header

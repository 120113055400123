// // import React, { useState } from 'react';
// // import Logo from '../Images/user.PNG';
// // import {BiSolidDashboard} from 'react-icons/bi';
// // import {BsLayersFill} from 'react-icons/bs';
// // import {FaWpforms, FaAngleDown} from 'react-icons/fa';
// // import {AiFillCar} from 'react-icons/ai';
// // import {HiLocationMarker} from 'react-icons/hi';
// // import { Link, Outlet } from 'react-router-dom';


// // export default function Sidebar() {
    
// //   return (
// //     <>
   
//    <div className="flex flex-col w-[220px] shadow-[rgba(0,_0,_0,_0.25)_0px_25px_50px_-12px] h-screen">
//               <button className="relative text-sm focus:outline-none group">
//                   <div className="flex items-center justify-between w-full text-red-900 text-2xl h-16 px-4  border-gray-300 hover:bg-gray-300">
//                       <span className=" font-[600] text-lg text-[#188ae2] ">
//                       HINDUSTANRIDES
//                       </span> 
                     
//                   </div>
                
//               </button>
//               <div className="flex flex-col flex-grow overflow-auto">
//                   <div className='flex'>
//                   <header>
//                             <div className="flex mb-2 ">
//                                 <a className="relative inline-flex items-start mr-2 p-2" href="#0">
//                                     <div className="absolute top-0 right-0 -mr-2 bg-white rounded-full shadow" aria-hidden="true">
                                       
//                                     </div>
//                                     <img className="rounded-full" src={Logo} width="60" height="74" alt="User 01" />
//                                 </a>
//                                 <div className="mt-1 pr-3 p-2">
//                                     <a className="inline-flex text-gray-800 hover:text-gray-900" href="#0">
//                                         <h2 className="text-[16px] leading-snug justify-center font-semibold text-[#333]">
//                                         John Doe
//                                         </h2>
//                                     </a>
//                                     <div className="flex items-center">
//                                         <span className="text-sm font-medium -mt-0.5 mr-1 text-[#777]">Car Admin</span></div>
//                                 </div>
//                             </div>
//                         </header>

//                   </div>
                  
//                    <div className='mt-3'>
//                    <div className="flex p-5 space-x-7 group hover:bg-[#c7def0] ">
//                         <BiSolidDashboard size={25}  />
//                        <a href='/'> Dashboard  </a>
//                     </div>
//                         <div className='flex p-5 space-x-7 group hover:bg-[#c7def0]'>
//                             <BsLayersFill size={20} />
//                             <a className=''>Layout</a>
//                         </div>
//                         <div className='flex p-5 space-x-7 group hover:bg-[#c7def0]' >
//                             <AiFillCar size={20}  />
//                             <a className='' href='/car-form'>Car Forms</a>
//                         </div>
//                         <div className='flex p-5 space-x-7 group hover:bg-[#c7def0]' >
//                             <HiLocationMarker size={20}  />
//                             <a className=''>City Forms</a>
//                         </div>
//                         <div className='flex p-5 space-x-7 group hover:bg-[#c7def0]' >
//                             <FaWpforms size={20}  />
//                             <a className=''>Car Detail Forms</a>
//                         </div>
                   
                     
//                    </div>
//               </div>

//           </div>
         
// //     </>
// //   )
// // }
// import React, { useState } from 'react';
// import {
//     FaTh,
//     FaBars,
//     FaUserAlt,
//     FaRegChartBar,
//     FaCommentAlt,
//     FaShoppingBag,
//     FaThList
// }from "react-icons/fa";
// import { NavLink } from 'react-router-dom';
// import Logo from '../Images/user.PNG';
// import {BiSolidDashboard} from 'react-icons/bi';
// import {BsLayersFill} from 'react-icons/bs';
// import {FaWpforms, FaAngleDown} from 'react-icons/fa';
// import {AiFillCar} from 'react-icons/ai';
// import {HiLocationMarker} from 'react-icons/hi';
// import { Link, Outlet } from 'react-router-dom';


// const Sidebar = ({children}) => {
//     const[isOpen ,setIsOpen] = useState(false);
//     const toggle = () => setIsOpen (!isOpen);
//     const menuItem=[
//         {
//             path:"/",
//             name:"Dashboard",
//             icon:<FaTh/>
//         },
//         {
//             path:"/about",
//             name:"About",
//             icon:<FaUserAlt/>
//         },
//         {
//             path:"/analytics",
//             name:"Analytics",
//             icon:<FaRegChartBar/>
//         },
//         {
//             path:"/comment",
//             name:"Comment",
//             icon:<FaCommentAlt/>
//         },
//         {
//             path:"/product",
//             name:"Product",
//             icon:<FaShoppingBag/>
//         },
//         {
//             path:"/productList",
//             name:"Product List",
//             icon:<FaThList/>
//         }
//     ]
//     return (
//         <div className="container">
//              <div className="flex flex-col w-[220px] shadow-[rgba(0,_0,_0,_0.25)_0px_25px_50px_-12px] h-screen">
//               <button className="relative text-sm focus:outline-none group">
//                   <div className="flex items-center justify-between w-full text-red-900 text-2xl h-16 px-4  border-gray-300 hover:bg-gray-300">
//                       <span className=" font-[600] text-lg text-[#188ae2] ">
//                       HINDUSTANRIDES
//                       </span> 
                     
//                   </div>
                
//               </button>
//               <div className="flex flex-col flex-grow overflow-auto">
//                   <div className='flex'>
//                   <header>
//                             <div className="flex mb-2 ">
//                                 <a className="relative inline-flex items-start mr-2 p-2" href="#0">
//                                     <div className="absolute top-0 right-0 -mr-2 bg-white rounded-full shadow" aria-hidden="true">
                                       
//                                     </div>
//                                     <img className="rounded-full" src={Logo} width="60" height="74" alt="User 01" />
//                                 </a>
//                                 <div className="mt-1 pr-3 p-2">
//                                     <a className="inline-flex text-gray-800 hover:text-gray-900" href="#0">
//                                         <h2 className="text-[16px] leading-snug justify-center font-semibold text-[#333]">
//                                         John Doe
//                                         </h2>
//                                     </a>
//                                     <div className="flex items-center">
//                                         <span className="text-sm font-medium -mt-0.5 mr-1 text-[#777]">Car Admin</span></div>
//                                 </div>
//                             </div>
//                         </header>

//                   </div>
                  
//                    <div className='mt-3'>
//                    <div className="flex p-5 space-x-7 group hover:bg-[#c7def0] ">
//                         <BiSolidDashboard size={25}  />
//                        <a href='/'> Dashboard  </a>
//                     </div>
//                         <div className='flex p-5 space-x-7 group hover:bg-[#c7def0]'>
//                             <BsLayersFill size={20} />
//                             <a className=''>Layout</a>
//                         </div>
//                         <div className='flex p-5 space-x-7 group hover:bg-[#c7def0]' >
//                             <AiFillCar size={20}  />
//                             <a className='' href='/car-form'>Car Forms</a>
//                         </div>
//                         <div className='flex p-5 space-x-7 group hover:bg-[#c7def0]' >
//                             <HiLocationMarker size={20}  />
//                             <a className=''>City Forms</a>
//                         </div>
//                         <div className='flex p-5 space-x-7 group hover:bg-[#c7def0]' >
//                             <FaWpforms size={20}  />
//                             <a className=''>Car Detail Forms</a>
//                         </div>
                   
                     
//                    </div>
//               </div>

//           </div>
//            <main>{children}</main>
//         </div>
//     );
// };

// export default Sidebar;
// import React from 'react';
// import { Outlet } from 'react-router-dom';

// const Sidebar = () => {
//   return (
//     <aside className="fixed bg-blue-800 text-white w-64 h-screen mt-10">
//       <div className="p-4 flex items-center justify-center">
//         <span className="text-3xl font-bold">Logo</span>
//       </div>
//       <ul className="p-4">
//         <li className="py-2 hover:bg-blue-600 cursor-pointer">Dashboard</li>
//         <li className="py-2 hover:bg-blue-600 cursor-pointer">Reports</li>
//         <li className="py-2 hover:bg-blue-600 cursor-pointer">Settings</li>
//       </ul>
      
//       <Outlet/>
//     </aside>
//   );
// };

// export default Sidebar;


import React from 'react'
import 
{BsCart3, BsGrid1X2Fill, BsFillArchiveFill, BsFillGrid3X3GapFill, BsPeopleFill, 
  BsListCheck, BsMenuButtonWideFill, BsFillGearFill}
 from 'react-icons/bs'
import { Link, Outlet } from 'react-router-dom';
import Logo from '../Images/user.PNG';
import {BiSolidDashboard} from 'react-icons/bi';
import {BsLayersFill} from 'react-icons/bs';
import {FaWpforms, FaAngleDown} from 'react-icons/fa';
import {AiFillCar} from 'react-icons/ai';
import {HiLocationMarker} from 'react-icons/hi';
import {MdTour} from 'react-icons/md';
import { RiMemoriesFill } from "react-icons/ri";


function Sidebar({openSidebarToggle, OpenSidebar,  setOpenSidebarToggle}) {
    const handleMenuClick = () => {
        if (window.innerWidth <= 768) { // Assuming 768px as the mobile screen width breakpoint
          setOpenSidebarToggle(false);
        }
      };
  return (
    <aside id="sidebar" className={openSidebarToggle ? "sidebar-responsive": ""}>
         <div className="flex flex-col w-[260px] shadow-[rgba(0,_0,_0,_0.25)_0px_25px_50px_-12px] h-screen overflow-x">
              <button className="relative text-sm focus:outline-none group">
                  <div className=" mt-4 items-center justify-between w-full text-red-900 text-2xl h-16 px-4  border-gray-300 hover:bg-gray-300">
                      {/* <h1 className=" font-[600] text-[#188ae2] text-[24px] ">
                      TAXIWALA
                      </h1> 
                      <p className='text-[12px] mt-[-10px] ml-[72px] text-[#000]'> Taxi services </p> */}
                     <Link to='/dashboard'>
                        <img  src='https://taxiwalataxi.in/static/media/Taxi%20wala%202.c13555739e1553580ab7.png' className='px-5'/>
                    </Link>
                  </div>
                
              </button>
              <div className="flex flex-col flex-grow overflow-auto mt-5">
                  <div className='flex'>
                  <header>
                            <div className="flex mb-2 ">
                                <a className="relative inline-flex items-start mr-2 p-2" href="#0">
                                    <div className="absolute top-0 right-0 -mr-2 bg-white rounded-full shadow" aria-hidden="true">
                                       
                                    </div>
                                    <img className="rounded-full" src={Logo} width="60" height="74" alt="User 01" />
                                </a>
                                <div className="mt-1 pr-3 p-2">
                                    <a className="inline-flex text-gray-800 hover:text-gray-900" href="#0">
                                        <h2 className="text-[16px] leading-snug justify-center font-semibold text-[#333]">
                                        John Doe
                                        </h2>
                                    </a>
                                    <div className="flex items-center">
                                        <span className="text-sm font-medium -mt-0.5 mr-1 text-[#777]">Car Admin</span></div>
                                </div>
                            </div>
                        </header>

                  </div>
                  
                   <div className='mt-3'>
                   <div className="flex p-5 space-x-7 group hover:bg-[#c7def0] ">
                        <BiSolidDashboard size={25}  />
                       <Link to='/dashboard' onClick={handleMenuClick}> Dashboard  </Link>
                    </div>
                    <div>
  <div className="flex p-5 pb-0 space-x-7 group hover:bg-[#c7def0]">
    <AiFillCar size={30} />
    <Link to='/carDetails' onClick={handleMenuClick}>Car Details Hindustan Rental Taxi</Link>
  </div>

  <div className="mt-5 border-2 border-dashed"></div> 

  <div className="flex p-5 space-x-7 group hover:bg-[#c7def0]">
    <AiFillCar size={20} />
    <Link to='/car-form' onClick={handleMenuClick}>Car Forms</Link>
  </div>
</div>


                        <div className='flex p-5 space-x-7 group hover:bg-[#c7def0]' >
                            <HiLocationMarker size={20}  />
                           <Link to='/city-form' onClick={handleMenuClick}>City Forms</Link>
                        </div>
                        <div className='flex p-5 space-x-7 group hover:bg-[#c7def0]' >
                            <FaWpforms size={20}  />
                            <Link to='/car-details-Form' onClick={handleMenuClick}>Car Detail Forms</Link>
                        </div>
                        <div className='flex p-5 space-x-7 group hover:bg-[#c7def0]' >
                            <HiLocationMarker size={20}  />
                           <Link to='/local-dropcity-form' onClick={handleMenuClick}>Local DropCity Form</Link>
                        </div>
                        <div className='flex p-5 space-x-7 group hover:bg-[#c7def0]' >
                            <HiLocationMarker size={20}  />
                           <Link to='/local-dropcity-detail' onClick={handleMenuClick}>Local DropCity Details Form</Link>
                        </div>
                        <div className='flex p-5 space-x-7 group hover:bg-[#c7def0]' >
                            <MdTour size={20}  />
                            <Link to="/tour" onClick={handleMenuClick}>Tour Detail Forms</Link>
                        </div>
                        <div className='flex p-5 space-x-7 group hover:bg-[#c7def0]' >
                            <RiMemoriesFill size={20}  />
                            <Link to='/car-memories' onClick={handleMenuClick}>Memories Forms</Link>
                        </div>
                        <div className='flex p-5 space-x-7 group hover:bg-[#c7def0]' >
                            <FaWpforms size={20}  />
                            <Link to='/car-cab-collection' onClick={handleMenuClick}>CarCab Collection Forms</Link>
                        </div>
                        <div className='flex p-5 space-x-7 group hover:bg-[#c7def0]' >
                            <FaWpforms size={20}  />
                            <Link to='/booking-detail' onClick={handleMenuClick}>Booking Detail</Link>
                        </div>
                        <div className='flex p-5 space-x-7 group hover:bg-[#c7def0]' >
                            <FaWpforms size={20}  />
                            <Link to='/contact-detail' onClick={handleMenuClick}>Contacts Detail</Link>
                        </div>
                        <div className='flex p-5 space-x-7 group hover:bg-[#c7def0]' >
                            <FaWpforms size={20}  />
                            <Link to='/blog' onClick={handleMenuClick}>Blog </Link>
                        </div>
                   </div>
              </div>

          </div>  
        <Outlet/>
    </aside>
   
  )
}

export default Sidebar
